import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FRONTEND_DATA } from "../../globals";
import _ from 'lodash';
import { store } from "../../index";
import selectors from "../../selectors"
import { getCRDTItem } from "../multi-user/redux";
import getLocalCSSParser from './get-local-parser';
import getGlobalCSSParser from './get-global-parser';
import * as Sentry from "@sentry/browser";

export class PropWatcherManager {

	constructor(config) {

		this.config = config;

		this.parserType = config.parserType;
		this.parsers = config.parsers;

		if(!this.parserType) {
			throw 'PropWatcherManager constructor config field "parserType" must be set to either "global" or "local".';
		}

		if(!this.parsers) {
			throw 'PropWatcherManager constructor config field "parsers" must be set.';
		}

		this.propWatcherMap = {};
		this.propWatcherValues = {};
		this.requiresLocalParser = false;

	}

	getActiveParser() {

		return this.parsers[this.parserType];

	}

	// We cannot generate more than one instance of any watcher otherwise
	// nested inheritance won't work (the chain is broken if we generate a new
	// parent watcher every time we request inheritance)
	memoizedGetPropWatcher = _.memoize((config) => {

		return this.parsers[config.parser]?.getPropWatcher(config.selector, config.property, true)

	}, (config) => {

		if(!this.parsers[config.parser]) {
			// do not cache results if there's no parser to be found
			return undefined
		}

		// can only create one unique watcher per parser / selector / property combo
		return config.parser + '/' + config.selector + '/' + config.property
	})

	reloadWatchers(watchedProperties) {

		// wipe all old watchers before we update any of the old config
		this.resetWatchers();

		if (watchedProperties) {

			_.each(watchedProperties, (properties, selector) => {

				properties.forEach(property => {

					const watcher = this.initializeWatcher(selector, property);

					if(!watcher) {
						console.log('unable to initialize propWatcher', selector, property);
						return;
					}

					if(!this.propWatcherMap.hasOwnProperty(watcher.selector)) {
						this.propWatcherMap[watcher.selector] =  {};
					}

					if(this.propWatcherMap[watcher.selector].hasOwnProperty(watcher.propertyName)){
						// console.log('WARNING: same propwatcher defined multiple times. This should probably not happen.', watcher);
					}

					// store the watcher keyed by it's property
					this.propWatcherMap[watcher.selector][watcher.propertyName] = watcher

				})

			})

			this.runFullInheritanceParse();

			// set initial values
			_.each(this.propWatcherMap, (properties, selector) => {

				this.propWatcherValues[selector] =  {};

				_.each(properties, (watcher, property) => {
					
					this.propWatcherValues[selector][property] = watcher.value
					
					// add a change listener
					watcher.on('change', this.onPropWatcherChange);

				});

			})

		};

	}

	runFullInheritanceParse = () => {

		// run a parse to initialize all watchers
		this.parsers.global.parseCSS();
		// parse local last because it can refer back to global for inheritance
		this.parsers.local?.parseCSS();

		// run both parsers again. This helps grab inherited values
		// that occur in the wrong order. This needs to get fixed in
		// the css parser itself but for now it'll do
		this.parsers.global.parseCSS();
		this.parsers.local?.parseCSS();

	}

	initializeWatcher = (selector, config) => {

		//console.log('INIT WATCHER', selector, config.property, config)

		if(typeof config === "object") {

			// property is passed a configuration object
			if(!config.property) {
				return;
			}

			// config can contain the selector for the propWatcher, but will default
			// to the selector passed to this method
			config.selector = config.selector || selector;

			config.parser = config.parser || this.parserType;

		} else if(typeof config === "string") {

			// the config is just a string representing the property's name. Create
			// a simple config object
			config = {
				selector: selector,
				parser: this.parserType,
				property: config
			}

		}

		if(config.parser === 'local') {
			this.requiresLocalParser = true;
		}

		if(this.config.beforePropWatcherCreation) {
			this.config.beforePropWatcherCreation(config);
		}

		let watcher = this.memoizedGetPropWatcher(config);

		if(watcher && config.inheritsFrom) {

			const inheritedWatchers = _.map(
				_.isArray(config.inheritsFrom) ? config.inheritsFrom : [config.inheritsFrom],
				inheritedWatcherConfig => {
					return this.initializeWatcher(undefined, inheritedWatcherConfig)
				}
			);
			
			if(inheritedWatchers.length > 0) {

				if(watcher.propWatchersToInheritFrom) {

					const newInheritedWatchers = _.difference(inheritedWatchers, watcher.propWatchersToInheritFrom);

					if(newInheritedWatchers.length > 0) {
						// console.log('merging in new parent watchers', newInheritedWatchers, 'into', [...watcher.propWatchersToInheritFrom]);
						watcher.inheritFrom([
							...watcher.propWatchersToInheritFrom,
							...newInheritedWatchers
						]);
					}

				} else {
					watcher.inheritFrom(inheritedWatchers);
				}

			}
		}

		return watcher;

	}

	applyChanges = (changes) => {

		//console.log('apply', changes)

		if(changes && Object.keys(changes).length > 0) {

			this.parsers[this.parserType].mutateSharedType(() => {

				_.each(changes, (changedProperties, selector) => {

					if(typeof changedProperties !== 'object') {
						console.log('Unable to apply', changedProperties, 'to', selector)
						return;
					}

					_.each(changedProperties, (newValue, property) => {

						const watcherToUpdate = this.propWatcherMap[selector]?.[property];

						if(watcherToUpdate) {

							if(
								newValue instanceof Object
								&& newValue.hasOwnProperty('text')
							){ 
								newValue = newValue.text;
							}

							watcherToUpdate.setValue(newValue);
						} else {
							console.log('Unable to assign', newValue, 'to', selector, '/' ,property)
						}

					});

				});

			});

		}

	}

	onPropWatcherChange = (newValue, oldValue, watcher) => {

		// console.log('watcher ', watcher.selector, watcher.propertyName, 'changed from',oldValue, ' to', newValue)

		// clone old state
		const newPropwatcherValues = _.cloneDeep(this.propWatcherValues);
		// console.log('>>', this.propWatcherValues, newPropwatcherValues)

		// apply change to new state
		newPropwatcherValues[watcher.selector][watcher.propertyName] = watcher.value;

		// overwrite the old values
		this.propWatcherValues = newPropwatcherValues;

		if(this.config.onPropWatcherChange) {
			this.config.onPropWatcherChange(newValue, oldValue, watcher)
		}

	}

	resetWatchers() {

		// delete cached propWatchers
		this.memoizedGetPropWatcher.cache.clear();

		_.each(this.propWatcherMap, (properties, selector) => {
			_.each(properties, (watcher, property) => {
				watcher.destroy();
			});
		});

		this.propWatcherMap = {};
		this.propWatcherValues = {};

	}

	destroy() {
		this.resetWatchers();
	}

}

export const withPropWatchers = ( WrappedComponent, componentConfig ) => {

	const WrapperComponent = class extends Component {

		constructor(props) {

			super(props);

			this.parserType = typeof componentConfig.parser === "function" ? componentConfig.parser(props) : componentConfig.parser;

			this.propWatcherManager = new PropWatcherManager({
				parserType: this.parserType,
				parsers: {
					local: getLocalCSSParser(props.PIDBeingEdited, store.getState().pages.byId[props.PIDBeingEdited]?.local_css),
					global: getGlobalCSSParser(store.getState().css.stylesheet)
				},
				onPropWatcherChange: this.onPropWatcherChange,
				beforePropWatcherCreation: (config) => {
					if(componentConfig.beforePropWatcherCreation) {
						componentConfig.beforePropWatcherCreation(this._latestProps, config);
					}
				}
			});


			this.currentWatchedProperties = typeof componentConfig.watchedProperties === "function" ? componentConfig.watchedProperties(props) : componentConfig.watchedProperties;
			this._latestProps = props;

			// run initial setup
			this.propWatcherManager.reloadWatchers(this.currentWatchedProperties);

		}

		onPropWatcherChange = (newValue, oldValue, watcher) => {

			// re-render
			this.forceUpdate();

		}

		deleteStyleFromSelector = selector => {

			// find all rules related to this selector
			const parsedRulesList = this.propWatcherManager.getActiveParser().getParsedRules([
				selector,
				selector + ' a',
				selector + ' a:hover',
				selector + ' a:active',
				selector + ' a.active',
				'.mobile ' + selector,
				'.mobile ' + selector + ' a',
				'.mobile ' + selector + ' a:hover',
				'.mobile ' + selector + ' a:active',
				'.mobile ' + selector + ' a.active'
			]);

			// delete the last rules first so we don't get in trouble with 
			// updated start and end indexes (deleting something in front of these will move them down)
			parsedRulesList.sort((a,b) => {
				return b.parsedRule.start - a.parsedRule.start
			});

			parsedRulesList.forEach(rule => {
				
				const {start, end} = rule.parsedRule;

				this.propWatcherManager.getActiveParser().deleteCSSRange(start, end, {
					includeLeadingLineBreaks: false,
					includeTrailingLineBreaks: true
				});

			});

			// run a parse of the new CSS string
			this.propWatcherManager.getActiveParser().parseCSS();
			
		}

		hasStylesInSelector = (selector) => {

			const parsedRuleList = this.propWatcherManager.getActiveParser().getParsedRules([
				selector,
				selector + ' a',
				selector + ' a:hover',
				selector + ' a:active',
				'.mobile ' + selector,
				'.mobile ' + selector + ' a',
				'.mobile ' + selector + ' a:hover',
				'.mobile ' + selector + ' a:active'
			]);

			if( parsedRuleList && parsedRuleList?.length > 0 && parsedRuleList[0]?.parsedProperties.size > 0 ){
				return true
			} 

			return false
		}

		queuePropwatcherChanges = changes => {

			// potential optimization would be to batch changes here. However it's
			// caused issues before as it can cause UI to get out of sync
			this.propWatcherManager.applyChanges(changes);

		};

		resetStyle = () => {

			if(this.parserType === 'local' && this.props.PIDBeingEdited) {

				const {CRDTItem: pageCRDT} = getCRDTItem({
					reducer: 'pages.byId',
					item: this.props.PIDBeingEdited
				});

				// The local style parser's yText is not part of the CRDT yet. Insert it first,
				// then clear.
				if(!(pageCRDT.get('local_css') instanceof Y.Text)) {
					pageCRDT.set('local_css', this.propWatcherManager.getActiveParser().yText);
				}

			}

			this.propWatcherManager.getActiveParser().clearCSS();

			// reset watchers
			this.propWatcherManager.reloadWatchers(this.currentWatchedProperties);

		}

		componentDidMount(){
		}

		componentWillUnmount(){
			this.propWatcherManager.destroy();
		};

		shouldComponentUpdate = (nextProps, nextState) => {

			let localParserChanged = false;

			if(
				// if the parser uses local CSS
				this.propWatcherManager.requiresLocalParser
				// and the active PID has changed
				&& this.props.PIDBeingEdited !== nextProps.PIDBeingEdited
				// and the new active PID is not undefined
				&& nextProps.PIDBeingEdited
			) {

				// New PID is being edited. Update the local parser
				this.propWatcherManager.parsers.local = getLocalCSSParser(nextProps.PIDBeingEdited, store.getState().pages.byId[nextProps.PIDBeingEdited]?.local_css);

				localParserChanged = true;

			}

			// if componentConfig.watchedProperties is a function, it can be updated depending on the props passed
			if(
				localParserChanged
				|| (
					this.props !== nextProps
					&& typeof componentConfig.watchedProperties === "function"
					&& this.currentWatchedProperties !== componentConfig.watchedProperties(nextProps)
					&& !_.isEqual(this.currentWatchedProperties, componentConfig.watchedProperties(nextProps))
				)
			) {

				this.currentWatchedProperties = componentConfig.watchedProperties(nextProps);
				this._latestProps = nextProps;

				this.propWatcherManager.reloadWatchers(this.currentWatchedProperties);

			}

			return true;

		}

		render(){

			const activeParser = this.propWatcherManager.getActiveParser();

			if(!activeParser) {
				// Do not render if we are not able to get the parser 
				// the wrapped component relies on
				return null;
			}

			// filter out internal props
			const {PIDBeingEdited, dispatch, ...externalProps} = this.props;

			return (
				<WrappedComponent 
					{...externalProps}
					propWatcherMap={this.propWatcherManager.propWatcherMap}
					propWatcherValues={this.propWatcherManager.propWatcherValues}
					queuePropwatcherChanges={this.queuePropwatcherChanges}
					resetStyle={this.resetStyle}
					deleteStyleFromSelector={this.deleteStyleFromSelector}
					hasStylesInSelector={ this.hasStylesInSelector }
					parser={activeParser}
				/>
			);

		};
	};

	return connect(
		(state) => {
			return {
				PIDBeingEdited: state.frontendState.PIDBeingEdited
			}
		}
	)(WrapperComponent);

};
