import { getCRDTItem, hasCRDTItem } from "../multi-user/redux";
import { Y } from "../multi-user";
import _ from 'lodash';
import CargoCSSParser from "./cargo-css-parser";
import { setupSharedCSSListener } from './shared-css-listener';
import { YTransactionTypes } from "../../globals";

const getLocalCSSParser = _.memoize((pid, defaultStylesheet) => {

	if(!pid) {
		return;
	}

	defaultStylesheet = defaultStylesheet || '';

	let parser;

	// if we have this page in the CRDT already, grab the local CSS YText from there
	if(hasCRDTItem({
		reducer: 'pages.byId',
		item: pid
	})) {

		const {CRDTItem} = getCRDTItem({
			reducer: 'pages.byId',
			item: pid
		})
		
		if(CRDTItem.get('local_css') instanceof Y.Text) {

			// we have a proper shared Y.Text instance
			parser = new CargoCSSParser(CRDTItem.get('local_css'), defaultStylesheet);

		}

	}

	if(!parser) {
		// css is not shared yet
		parser = new CargoCSSParser(new Y.Text(), defaultStylesheet);
	}

	// listen for the yText being inserted or removed from the CRDT
	setupSharedCSSListener(parser, {
			local: true,
			pid
		});

	parser.onPendingUpdate = (pendingYText) => {

		const { CRDTItem } = getCRDTItem({
			reducer: 'pages.byId',
			item: pid
		});

		if(CRDTItem){
			
			// and merge yText into the page upon first edit
			ydoc.transact(() => {
				
				if(CRDTItem.get('local_css') instanceof Y.Text === false) {
					CRDTItem.set('local_css', pendingYText);
				}

			});

		} else {
			console.error("The CRDT item for this page couldn't be found.");
		}

	};

	return parser;

}, (pid, defaultStylesheet) => {
	// only run this once per pid
	return pid
});

export default getLocalCSSParser;