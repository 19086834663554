import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createSelector } from 'reselect';

import opentype from 'opentype.js'
import { parse as parseFontFaceSrc } from 'css-font-face-src';

import { FRONTEND_DATA } from '../../globals';
import { actions } from "../../actions";
import { Formik, Field } from 'formik';
import FormikChangeListener from "../ui-kit/formik-change-listener";
import { withPropWatchers } from '../../lib/shared-css';

import { TextButton, MessageContext, Select } from "@cargo/ui-kit";
import _, { has } from 'lodash';
import { CRDTState } from "../../globals";

const StylisticSetButton = (props) => {
    const {form, field, label, className } = props;

    const value = field?.value;

    const onClick = () => {
        form.setFieldValue(field.name, !field.value);
    }

    return (
        <TextButton
            onClick={(e) => props.onClick ? props.onClick(e) : onClick()}
            label={label}
            className={`stylistic-set-button${field?.value ? ' active' : ''}${className ? ' ' + className : ''}`}
        />
    )
}

const getFontAttributes = async (selector) => {

    const clientFrame = document.getElementById('client-frame');

    // Get the css rules from the stylesheets in the client frame that match the this.props.selector

    let fontFamily = null;
    let fontWeight = null;
    let fontStyle = null;

    for ( const sheet of clientFrame.contentDocument.styleSheets ) {

        // If the sheet has an href, skip it
        if (sheet.href) {
            continue;
        }

        // If the sheet's owner node has an id, skip it
        if (sheet.ownerNode && sheet.ownerNode.id) {
            continue;
        }

        // If the sheet has no rules, skip it
        if (sheet.cssRules.length === 0) {
            continue;
        }

        let rule = null;
        // Loop through the rules in the sheet to find the first rule that matches the selector
        for ( const r of sheet.cssRules ) {
            if (r.selectorText === selector) {
                rule = r;
                break;
            }
        }

        // If no rule was found, skip the sheet
        if (!rule) {
            continue;
        }

        // If the rule has a font-family, set the fontFamily variable
        if (rule.style.fontFamily) {
            fontFamily = rule.style.fontFamily;
            // If the fontFamily has quotes, remove them
            if (fontFamily.startsWith('"') && fontFamily.endsWith('"')) {
                fontFamily = fontFamily.slice(1, -1);
            }
        }

        // If the rule has a font-weight, set the fontWeight variable
        if (rule.style.fontWeight) {
            fontWeight = rule.style.fontWeight;
        }

        // If the rule has a font-style, set the fontStyle variable
        if (rule.style.fontStyle) {
            fontStyle = rule.style.fontStyle;
        }

    }

    return {fontFamily, fontWeight, fontStyle};

}

const getFontWeightAlternate = (fontWeight) => {
    const lookup = {
        100: 'thin',
        200: 'extra-light',
        300: 'light',
        400: 'normal',
        500: 'medium',
        600: 'semi-bold',
        700: 'bold',
        800: 'extra-bold',
        900: 'black',
    }

    if (lookup[fontWeight]) {
        return lookup[fontWeight];
    }

    if (Object.values(lookup).includes(fontWeight)) {
        return Object.keys(lookup).find(key => lookup[key] === fontWeight);
    }

    return fontWeight;

}

const getFontSrc = async ({fontFamily, fontWeight, fontStyle}) => {

    const cacheKeys = Object.keys(localStorage).filter(key => key.includes('type-settings-glyphs-src-cache-'));
    if (cacheKeys.length > 10) {
        // Get the oldest cache key
        const oldestKey = cacheKeys.reduce((oldest, key) => {
            const timestamp = parseInt(key.split('-').pop());
            if (timestamp < oldest.timestamp) {
                return {key, timestamp};
            }
            return oldest;
        }, {key: null, timestamp: Date.now()});
        // Delete the oldest cache key
        if (oldestKey.key) {
            // Get the corresponding cache value
            const cacheValue = JSON.parse(localStorage.getItem(oldestKey.key));
            // Get the corresponding font key
            const fontKey = 'type-settings-glyphs-parse-cache-' + cacheValue.url;
            // Delete the font cache value
            localStorage.removeItem(fontKey);
            localStorage.removeItem(oldestKey.key);
        }
    }

    const cacheKey = 'type-settings-glyphs-src-cache-' + encodeURIComponent(fontFamily + fontWeight + fontStyle);
    // If there are more than 10 cache keys, delete the oldest one
    const matchingKey = Object.keys(localStorage).find(key => key.includes(cacheKey));
    let cachedResult = null;

    try {
        // Get list of localStorage keys
        if(localStorage.getItem(matchingKey)) {
            cachedResult = JSON.parse(localStorage.getItem(matchingKey));
        }
    } catch(e) {
        // delete invalid cache key
        try {
            localStorage.removeItem(matchingKey);
        } catch(e) {console.error(e)}
    }

    // If the cache is valid, use the cached result and break the loop
    if (cachedResult) {
        return cachedResult;
    }

    const clientFrame = document.getElementById('client-frame');
    let css = '';
    let src = null;

    for ( const sheet of clientFrame.contentDocument.styleSheets ) {

        if (css.includes(fontFamily)) {
            break;
        }

        const url = sheet.href;

        if( sheet.ownerNode && FRONTEND_DATA.contentWindow.document.head.contains(sheet.ownerNode) && !url.includes('fonts')){
            continue;
        }

        // Only look at stylesheets that are not from the same origin as the current page
        if (!url || url.startsWith('../') || url.startsWith('./') || url.startsWith('/')) {
            // If any of the cssRules in the sheet are font-face declarations, add them to the css string
            for (const rule of sheet.cssRules) {
                if (rule.type === 5 && rule.cssText.includes(fontFamily)) {
                    css += rule.cssText;
                }
            }
            continue;
        }

        let text = null;
        try {
            const response = await fetch(url, {
                method: "GET",
                cache: "no-store",
                mode: "cors"
            });
            text = await response.text();
            // Reformat any relative urls in the CSS (text) to absolute urls using url
            const replacements = {
                '': '',
                // Remove the last 2 segments of the url	
                '../': url.replace(/\/[^\/]*\/[^\/]*$/, '/'),
                // Remove everything after the last slash
                './': url.replace(/\/[^\/]*$/, '/'),
                // Get the base url (everything before the first slash including the protocol)
                '/': new URL(url).origin + '/',
            }
            if (text.includes('url(./') || text.includes('url(../') || text.includes('url(/')) {
                text = text.replace(/url\((.*?)\)/g, (match, p1) => {
                    return `url(${replacements[p1.match(/^(\.\/|\.\.\/|\/)/)?.[1]]}${p1.replace(/^(\.\/|\.\.\/|\/)/, '')})`;
                });
            }
        } catch (e) {
            console.log('error fetching font', e);
            continue;
        }

        const fontFamilyWithoutQuotes = fontFamily.replace(/"/g, '').replace(/'/g, '');

        if (
            text.includes('@font-face') &&
            (
                // Check that the font family matches exactly, without any additional characters before or after
                text.includes(`font-family: ${fontFamilyWithoutQuotes};`) ||
                text.includes(`font-family: "${fontFamilyWithoutQuotes}";`) ||
                text.includes(`font-family: '${fontFamilyWithoutQuotes}';`)
            )
        ) {

            let stylesheet;
            try {
                stylesheet = await getIsolatedStyleSheet(text);
            } catch (e) {
                console.error('error getting isolated stylesheet', e);
                continue;
            }
            const rules = stylesheet.sheet.cssRules;
            // If the rule cssText includes the font-family, add it to the css string
            for (const rule of rules) {
                if (rule.cssText.includes(fontFamily)) {
                    let hasFontWeight = false;
                    let matchesFontWeight = false;
                    if (rule.style.fontWeight) {
                        hasFontWeight = true;
                        // Get the font-weight from the rule
                        const ruleFontWeight = rule.style.fontWeight;
                        // Check if the rule's font-weight is a range (e.g. 400 900)
                        if (fontWeight) {
                            if (ruleFontWeight.includes(' ')) {
                                // Check if the font-weight is within the range
                                const [min, max] = ruleFontWeight.split(' ');
                                matchesFontWeight = parseInt(fontWeight) >= parseInt(min) && parseInt(fontWeight) <= parseInt(max);
                            } else {
                                // Check if the font-weight matches the rule's font-weight or the alternate font-weight
                                matchesFontWeight = ruleFontWeight === fontWeight || ruleFontWeight === getFontWeightAlternate(fontWeight);
                            }
                        } else {
                            matchesFontWeight = true;
                        }
                    }
                    let hasFontStyle = false;
                    let matchesFontStyle = false;
                    if (rule.style.fontStyle) {
                        hasFontStyle = true;
                        matchesFontStyle = rule.cssText.includes(`font-style: ${fontStyle}`);
                    }
                    if (!hasFontWeight || matchesFontWeight) {
                        if (!hasFontStyle || matchesFontStyle) {
                            css += rule.cssText;
                        }
                    }
                }
            }
        }
    }

    if (!css || css === '') {
        return null;
    }

    let relevantStyleSheet;
    try {
        relevantStyleSheet = await getIsolatedStyleSheet(css);
    } catch (e) {
        console.error('error getting isolated stylesheet', e);
        return null;
    }

    // Loop through all the rules in the stylesheet
    for ( const rule of relevantStyleSheet.sheet.cssRules ) {

        // If the rule is a font-face rule
        if (rule.type === 5 || rule.type === CSSRule.FONT_FACE_RULE) {

            if(!rule.style.src) {
                continue;
            }

            const parsedSrc = parseFontFaceSrc(rule.style.src);
            let url = parsedSrc.find((obj) => obj.url)?.url;
            let format = parsedSrc.find((obj) => obj.format)?.format;

            if (!url || url.startsWith('../') || url.startsWith('./') || url.startsWith('/')) {
                console.error('no absolute url', rule, src);
                continue;
            } else {
                src = {url, format};
                // Cache the result
                localStorage.setItem(cacheKey + '-' + Date.now(), JSON.stringify(src));
                break;
            }

        }

    }

    return src;

}

const getIsolatedStyleSheet = async (css, depth=0, fetchedURLs=[]) => {

    const doc = document.implementation.createDocument('http://www.w3.org/1999/xhtml', 'body', null);
    const style = document.createElement('style');
    style.innerHTML = css;
    doc.children[0].appendChild(style);

    for ( const rule of style.sheet.cssRules ) {
        if (rule.type === 3 || rule.type === CSSRule.IMPORT_RULE && depth < 2 && fetchedURLs.indexOf(rule.href) == -1) {

            depth = depth+1;
            fetchedURLs.push(rule.href);

            let fetchedStyle = null;	

            try {
                const response = await fetch(rule.href);
                let text = await response.text();
                // Reformat any relative urls in the CSS (text) to absolute urls using rule.href
                const replacements = {
                    '': '',
                    // Remove the last 2 segments of the url
                    '../': rule.href.replace(/\/[^\/]*\/[^\/]*$/, '/'),
                    // Remove everything after the last slash
                    './': rule.href.replace(/\/[^\/]*$/, '/'),
                    // Get the base url (everything before the first slash including the protocol)
                    '/': new URL(rule.href).origin + '/',
                }
                if (text.includes('url(./') || text.includes('url(../') || text.includes('url(/')) {
                    text = text.replace(/url\((.*?)\)/g, (match, p1) => {
                        return `url(${replacements[p1.match(/^(\.\/|\.\.\/|\/)/)?.[1]]}${p1.replace(/^(\.\/|\.\.\/|\/)/, '')})`;
                    });
                }
                fetchedStyle = await getIsolatedStyleSheet(text, depth,fetchedURLs);
            } catch (e) {
                console.log('error fetching font', e);
                continue;
            }
            style.innerHTML+=fetchedStyle.textContent
        }

    }

    return style;

}

const parseFont = async (src) => {

    if (!src) {
        return null;
    }

    let parsed = null;

    const cacheKey = 'type-settings-glyphs-parse-cache-' + src.url;
    let cachedResult = null;

    try {
        if(localStorage.getItem(cacheKey)) {
            cachedResult = JSON.parse(localStorage.getItem(cacheKey));
        }
    } catch(e) {
        // delete invalid cache key
        try {
            localStorage.removeItem(cacheKey);
        } catch(e) {console.error(e)}
    }

    // If the cache is valid, use the cached result and break the loop
    if (cachedResult) {
        return cachedResult;
    }

    const format = src.format;

    let decompressBindingLoadPromise = null;

    const loadScript = (src) => new Promise((onload) => document.documentElement.append(
        Object.assign(document.createElement('script'), {src, onload})
    ));

    function typedArrayToBuffer(array) {
        return array.buffer.slice(array.byteOffset, array.byteLength + array.byteOffset)
    }

    // If the format is woff2, we need to decompress it before parsing
    if (format.includes('woff2')) {

        // decompress before parsing
        try {
            
            if (!decompressBindingLoadPromise) {

                decompressBindingLoadPromise = new Promise( async (resolve) => {
                    
                    const path = PUBLIC_URL + '/js/wawoff@2.0.1-decompress_binding.js';

                    // Check if the decompress binding is already loaded
                    if (window.Module) {
                        resolve();
                        return;
                    }

                    const init = new Promise((done) => window.Module = { onRuntimeInitialized: done});
                    await loadScript(path).then(() => init);

                    resolve();
                })

            }

            await decompressBindingLoadPromise;

            const buffer = await fetch(src.url, {
                method: "GET",
                cache: "no-store",
                mode: "cors"
            }).then(res => res.arrayBuffer());

            const out = window.Module.decompress(buffer);
            const result = opentype.parse(typedArrayToBuffer(out));

            const glyphs = result?.glyphs?.glyphs ? Object.keys(result?.glyphs?.glyphs).reduce((acc, key) => {
                const glyph = result?.glyphs?.glyphs[key];
                acc[key] = {
                    name: glyph.name,
                    unicode: glyph.unicode,
                }
                return acc;
            }, {}) : {};

            const features = result?.tables?.gsub?.features?.reduce((acc, feature) => {
                acc.push({
                    tag: feature.tag,
                    feature: {
                        lookupListIndexes: feature.feature.lookupListIndexes,
                    }
                })
                return acc;
            }, []);

            const lookups = result?.tables?.gsub?.lookups?.reduce((acc, lookup) => {
                acc.push({
                    index: lookup.index,
                    subtables: lookup.subtables.reduce((acc, subtable) => {
                        if (subtable.coverage?.glyphs || subtable.coverage?.ranges) {
                            acc.push({
                                coverage: {
                                    glyphs: subtable.coverage?.glyphs,
                                    ranges: subtable.coverage?.ranges,
                                },
                            })
                        }
                        acc.push(subtable);
                        return acc;
                    }, []),
                })
                return acc;
            }, []);

            parsed = {
                tables: {
                    gsub: {
                        features: features,
                        lookups: lookups,
                    },
                },
                glyphs: glyphs,
            }

            // Cache the result
            localStorage.setItem(cacheKey, JSON.stringify(parsed));

        } catch (e) {
            console.error('error parsing woff2 font', e);
        }

    } else {

        try {
            const buffer = await fetch(src.url, {
                method: "GET",
                cache: "no-store",
                mode: "cors"
            }).then(res => res.arrayBuffer());
            const result = opentype.parse(buffer);

            parsed = {
                tables: {
                    gsub: {
                        features: result?.tables?.gsub?.features,
                        lookups: result?.tables?.gsub?.lookups,
                    }
                },
                glyphs: result.glyphs.glyphs,
            }

            // Cache the result
            localStorage.setItem(cacheKey, JSON.stringify(parsed));
        } catch (e) {
            console.error('error parsing woff font', e, src.url, src.format);
        }

    }

    return parsed;

}

const getAlternatesFromFeature = (feature, parsed, raw = false) => {
    const replacements = [];
    if (feature?.feature?.lookupListIndexes?.length > 0) {
        const lookups = feature.feature.lookupListIndexes.map(index => parsed.tables.gsub.lookups[index]);
        const subtables = [];
        for (const lookup of lookups) {
            for (const subtable of lookup.subtables) {
                subtables.push(subtable);
            }
        }
        for (const subtable of subtables) {
            if ( subtable?.coverage?.glyphs ) {
                if (subtable?.coverage?.glyphs?.length > 0) {
                    const coverage = subtable.coverage.glyphs.map(glyphId => parsed.glyphs[glyphId]);
                    for (const glyph of coverage) {
                        if (glyph.name) {
                            replacements.push(glyph.name);
                        }
                    }
                }
            }
            if ( subtable?.coverage?.ranges ) {
                if (subtable?.coverage?.ranges?.length > 0) {
                    for (const range of subtable.coverage.ranges) {
                        for (let i = range.start; i <= range.end; i++) {
                            if (parsed.glyphs[i]?.name) {
                                replacements.push(parsed.glyphs[i].name);
                            }
                        }
                    }
                }
            }
        }
    }
    if (raw) {
        return replacements;
    }
    let readableReplacements = replacements.map(replacement => {
        const allowedCharacters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        const allowed = [
            ...allowedCharacters.split(''),
            'IJ',
            'ij',
            'exclam',
            'bullet',
            'asterisk',
            'period',
            'slash',
            'percent',
            'at',
            'degree',
            'one',
            'two',
            'three',
            'four',
            'five',
            'six',
            'seven',
            'eight',
            'nine',
            'zero',
            'multiply',
            'divide',
            'plus',
            'minus',
            'equal',
            'arrowup',
            'arrowdown',
            'arrowleft',
            'arrowright',
            'comma',
            'colon',
            'semicolon',
            'quoteleft',
            'quoteright',
            'quotedblleft',
            'quotedblright',
            'quotesingle',
            'registered',
            'ampersand',
            'parenleft',
            'parenright',
            'emdash',
            'endash',
            'question',
        ];
        if (allowed.includes(replacement)) {
            return replacement;
        }
        return 'Misc';
    });
    // If readable replacements has multiplel Misc values, remove all misc values and add a single Various misc value
    if (readableReplacements.filter(item => item === 'Misc').length > 1) {
        readableReplacements = readableReplacements.filter(item => item !== 'Misc');
        readableReplacements.push('Various Misc');
    }
    // Replace any duplicate values with a single value
    readableReplacements = [...new Set(readableReplacements)];

    const commonGroupings = {
        'arrows': [
            'arrowup',
            'arrowdown',
            'arrowleft',
            'arrowright',
        ],
        'numbers': [
            'one',
            'two',
            'three',
            'four',
            'five',
            'six',
            'seven',
            'eight',
            'nine',
            'zero',
        ],
        'I, J': [
            'I',
            'J',
            'IJ',
        ],
        'one, four': [
            'one',
            'four',
        ],
        'six, nine': [
            'six',
            'nine',
        ],
        'punctuation': [
            'period',
            'comma', 
            'colon', 
            'semicolon',
            'quotesingle',
            'exclam',
        ],
        'quotes': [
            'quoteleft',
            'quoteright',
            'quotedblleft',
            'quotedblright',
        ],
        'capitals': 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split(''),
        'lowercase': 'abcdefghijklmnopqrstuvwxyz'.split(''),
        'registered': ['registered', 'registered'],
        'dashes': ['emdash', 'endash'],
        'parentheses': ['parenleft', 'parenright'],
    }
    const keys = Object.keys(commonGroupings);
    // Reduce arrays containing common groupings to a single value
    for ( const grouping of keys) {
        const group = commonGroupings[grouping];
        if (grouping === 'punctuation') {
            if (readableReplacements.filter(item => group.includes(item)).length > 1) {
                readableReplacements = readableReplacements.filter(item => !group.includes(item));
                readableReplacements.push(grouping);
            }
        } else {
            if (readableReplacements.filter(item => group.includes(item)).length === group.length) {
                readableReplacements = readableReplacements.filter(item => !group.includes(item));
                readableReplacements.push(grouping);
            }
        }
    }
    // If the length of readable replacements is 2 and one of the values is Various Misc, replace with the other value
    if (readableReplacements.length === 2 && readableReplacements.includes('Various Misc')) {
        readableReplacements = readableReplacements.filter(item => item !== 'Various Misc');
    }
    if (readableReplacements.includes('capitals') && readableReplacements.includes('lowercase')) {
        // Remove the capital and lowercase values
        readableReplacements = readableReplacements.filter(item => item !== 'capitals' && item !== 'lowercase');
        // Add the letters value
        readableReplacements.push('letters');
    }
    // If readable replacemnts includes all four arrow directions and no other values or the value Various Misc, replace with Arrow
    if (readableReplacements.includes('arrowup') && readableReplacements.includes('arrowdown') && readableReplacements.includes('arrowleft') && readableReplacements.includes('arrowright') && readableReplacements.length === 4) {
        readableReplacements = ['arrows'];
    }
    return readableReplacements;
}

const getAlternatesStrFromFeature = (feature, parsed, src) => {
    const rawAlternates = getAlternatesFromFeature(feature, parsed, true);
    const specialCases = {
        'https://type.cargo.site/files/CargoReproVariable.woff2': {
            'ss01': 'Alt a',
            'ss02': 'Alt l',
            'ss03': 'Alt j',
            'ss04': 'Alt t',
            'ss05': 'Alt u',
            'ss06': 'Alt y',
            'ss07': 'Alt G',
            'ss08': 'Alt R',
            'ss09': 'Alt S',
            'ss10': 'Alt 2',
            'ss11': 'Alt 6 & 9',
            'ss12': 'Alt %',
            'ss13': 'Square Punctuation',
            'ss14': 'Roman Numerals',
            'ss15': 'Smallsize Centered',
            'ss16': 'Smallsize Circled',
        },
        'https://type.cargo.site/files/InfiniGF-Regular.woff': {
            'ss01': 'Arrows',
        },
        'https://type.cargo.site/files/InfiniGF-Bold.woff': {
            'ss01': 'Arrows',
            'ss04': 'Pictograms'
        },
        // All of the Gaisyr fonts have the same stylistic sets
        'https://type.cargo.site/files/CargoGaisyr': {
            'ss01': 'Simple Serifs',
            'ss02': 'Alt g',
            'ss03': 'Alt registered',
        }
    }
    // Handle special cases
    for (const key in specialCases) {
        if (src.url.startsWith(key)) {
            if (specialCases[key][feature.tag]) {
                return specialCases[key][feature.tag];
            } else {
                return null;
            }
        }
    }
    
    const alternates = getAlternatesFromFeature(feature, parsed);
    let str = alternates.reduce((acc, item) => {
        const lookup = {
            'one': '1',
            'two': '2',
            'three': '3',
            'four': '4',
            'five': '5',
            'six': '6',
            'seven': '7',
            'eight': '8',
            'nine': '9',
            'zero': '0',
            'multiply': 'x',
            'divide': '/',
            'plus': '+',
            'minus': '-',
            'equal': '=',
            'arrowup': '↑',
            'arrowdown': '↓',
            'arrowleft': '←',
            'arrowright': '→',
            'comma': ',',
            'colon': ':',
            'semicolon': ';',
            'quoteleft': '‘',
            'quoteright': '’',
            'quotedblleft': '“',
            'quotedblright': '”',
            'quotesingle': '’',
            'one, four': '1, 4',
            'six, nine': '6, 9',
            'parenleft': '(',
            'parenright': ')',
            'ampersand': '&',
            'question': '?',
        }
        if (lookup[item]) {
            item = lookup[item];
        }
        if (item.startsWith('Various') || item.startsWith('Misc')) {
            if (acc.length === 0 && alternates.length === 1) {
                return item;
            } else {
                return acc;
            }

        }
        if (acc) {
            return `${acc}, ${item}`;
        }
        return `Alt ${item}`;
    }, '');
    const commas = str.match(/,/g);
    // If the label only includes a single , remplace it with an and
    if (commas && commas.length === 1) {
        str = str.replace(/,([^,]*)$/, ' and$1');
    }
    // If the label is longer than 26 characters, exclude it
    if (str.length > 26) {
        // Get the "count" from the last digits in the tag (e.g. ss01 -> 01)
        const count = feature.tag.match(/\d+$/)?.[0];
        return `Style ${count}`;
    }

    return str;

}

class TypeSettingsGlyphs extends Component {

	constructor(props) {
		super(props);

        this.state = {
            parsedFont: null,
            src: null,
            features: {},
        }    

        this.glyphsRef = createRef();
        this.messageRef = createRef();

	}

	render() {

        const initialValues = {
            fontFeatureSettings: [],
        };

        if (this.state.features) {
            for (const tag in this.state.features) {
                if (this.props.propWatcherValues?.[this.props.selector]?.['font-feature-settings']?.includes(tag)) {
                    initialValues[tag] = true;
                } else {
                    initialValues[tag] = false;
                }
            }
        }

        let fontFamily = this.props.propWatcherValues[this.props.selector]['font-family'] + '';
        // Split the font-family by commas and get the first value
        fontFamily = fontFamily.split(',')[0];
        // Remove any double quotes from the font-family
        fontFamily = fontFamily.replace(/"/g, '');
        // Remove any single quotes from the font-family
        fontFamily = fontFamily.replace(/'/g, '');

		return (
            <MessageContext.Consumer>
                {(Message) => {
                    // Set the Message ref 
                    this.messageRef.current = Message;

                    return (
                    <window-content>
                        <div className="window-header">
                            <window-label label-size="standard">
                                <div>
                                    {fontFamily}
                                </div>
                                <span style={{flex: 1}} />
                            </window-label>
                        </div>
                        <div className="uiWindow-spacer"></div>
                        <Formik
                            // this is important for websocket updating
                            enableReinitialize
                            initialValues={initialValues}
                        >
                        { props => {
                            const hasAdvancedOptions = Object.keys(this.state.features).length > 0 ? true : false;
                            const hasStylisticSets = this.state.features && Object.keys(this.state.features).some(key => key.startsWith('ss') || key.startsWith('cv'));
                            const hasAdditionalOptions = this.state.features && Object.keys(this.state.features).some(key => !key.startsWith('ss') && !key.startsWith('cv'));

                            return(
                                <>
                
                                    <FormikChangeListener onChange={this.onChange}/>
                
                                    <div 
                                        ref={this.containerRef}
                                        className="ui-group"
                                    >
                                        {this.state.parsedFont === null ? (
                                            <div className="message">Loading...</div>
                                        ) : null}
                                        {this.state.parsedFont !== null && Object.keys(this.state.parsedFont.glyphs).length === 0 ? (
                                            <div className="message">There was an error parsing this font.</div>
                                        ) : null}
                                        {hasStylisticSets ? (
                                            <>
                                                <div className="stylistic-set-buttons">
                                                {this.state.features && Object.keys(this.state.features).map((tag, index) => {
                                                    if (!tag.startsWith('ss') && !tag.startsWith('cv')) {
                                                        return null;
                                                    }
                                                    const feature = this.state.features[tag];
                                                    let label = feature.alternatesStr;
                                                    return {
                                                        tag,
                                                        label,
                                                    }
                                                }).reduce((acc, curr) => {
                                                    // Remove any duplicates
                                                    if (curr === null ) {
                                                        return acc;
                                                    }
                                                    if (curr.label === null) {
                                                        return acc;
                                                    }
                                                    if (curr.label === '') {
                                                        return acc;
                                                    }
                                                    if (acc.length === 0) {
                                                        acc.push(curr);
                                                        return acc;
                                                    }
                                                    if (acc.find(item => item.label === curr.label)) {
                                                        return acc;
                                                    }
                                                    acc.push(curr);
                                                    return acc;
                                                }, []).map((item) => {
                                                    return (
                                                        <Field 
                                                            key={item.tag}
                                                            component={StylisticSetButton}
                                                            name={item.tag} 
                                                            label={item.label}
                                                        />
                                                    )
                                                })}
                                                </div>
                                                <div className="uiWindow-spacer"></div>
                                            </>
                                        ) : null}
                                        <div ref={this.glyphsRef}/>
                                        {hasAdditionalOptions ? (
                                            <Field
                                                noBars={true}							
                                                component={Select}
                                                name="fontFeatureSettings"
                                                buttonLabel={"Font Feature Settings"}
                                                multiple={true}
                                                >
                                                    {Object.keys(this.state.features).map((tag, index) => {
                                                        if (tag.startsWith('ss')) {
                                                            return null;
                                                        }
                                                        const feature = this.state.features[tag];
                                                        let label = feature.label;
                                                        return (
                                                            <option display-text={label} value={tag} key={tag}>{label}</option>
                                                        )
                                                    }).reduce((acc, item) => {
                                                        if (item) {
                                                            acc.push(item);
                                                        }
                                                        return acc;
                                                    }, [])}
                                            </Field>
                                        ) : null}
                                    </div>

                                </>
                        )}}
                        </Formik>
                    </window-content>
                )}}
            </MessageContext.Consumer>
		)
	}

    componentDidMount() {
        this.handleMount();
    }

    componentDidUpdate(prevProps, prevState) {

        if (this.props.selector !== prevProps.selector || this.props.propWatcherValues?.[this.props.selector]?.['font-family'] !== prevProps.propWatcherValues?.[this.props.selector]?.['font-family'] || this.props.propWatcherValues?.[this.props.selector]?.['font-weight'] !== prevProps.propWatcherValues?.[this.props.selector]?.['font-weight'] || this.props.propWatcherValues?.[this.props.selector]?.['font-style'] !== prevProps.propWatcherValues?.[this.props.selector]?.['font-style']) {
            const isVariable = this.props.propWatcherValues?.[this.props.selector]?.['font-variation-settings'] ? true : false;
            if (isVariable) {
                if (this.props.propWatcherValues?.[this.props.selector]?.['font-family'] !== prevProps.propWatcherValues?.[this.props.selector]?.['font-family']) {
                    this.handleMount();
                }
            } else {
                this.handleMount();
            }
        }
        // If parsed font is set
        if (this.state.parsedFont && prevState.parsedFont !== this.state.parsedFont) {
            if (this.glyphsRef.current && this.state.src === null) {
                // Close the window if the font has no glyphs
                if (Object.keys(this.state.parsedFont.glyphs).length === 0) {
                    this.props.removeUIWindow(uiWindow => { 
                        return uiWindow.id === this.props.uiWindowProps.id
                    });
                }
            }
            if (this.glyphsRef.current && this.messageRef.current && this.state.src) {
                const Message = this.messageRef.current;
                // Remove any existing iframes
                const iframes = this.glyphsRef.current.querySelectorAll('iframe');
                for (const iframe of iframes) {
                    this.glyphsRef.current.removeChild(iframe);
                }
                // Create an iframe to load the font in
                const iframe = document.createElement('iframe');
                // Make the iframe 500px tall
                iframe.style.height = '460px';
                // Make the iframe 100% wide
                iframe.style.width = '100%';
                // Add a style tag to the iframe to style the glyphs
                const style = document.createElement('style');
                style.innerHTML = `
                    @font-face {
                        font-family: 'font';
                        src: url('${this.state.src.url}') format('${this.state.src.format}');
                    }
                    body {
                        margin: 0;
                        padding: 0px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                    .glyphs {
                        position: absolute;
                        top: 0;
                        left: 0;
                        display: grid;
                        grid-template-columns: repeat(5, 1fr);
                        grid-gap: 1px;
                        justify-content: center;
                        align-items: center;
                        height: 100%;
                        width: 100%;
                        font-family: 'font';
                        font-size: 24px;
                        overflow: scroll;
                        margin: 0px;
                        border-radius: 5px;
                        scrollbar-width: none;
                        -ms-overflow-style: none;
                    }
                    .glyphs::-webkit-scrollbar {
                        -webkit-appearance: none;
                        width: 0;
                        height: 0;
                    }
                    .glyph {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        aspect-ratio: 1 / 1;
                        text-align: center;
                        font-family: 'font';
                        font-size: 24px;
                        line-height: 24px;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        cursor: pointer;
                        background: white;
                        color: rgba(0,0,0,0.85);
                        border-radius: 5px;
                        -webkit-user-select: none;
                        -ms-user-select: none;
                        user-select: none;
                        max-width: 61.2px;
                    }
                    .glyph:active {
                        opacity: 0.7;
                    }
                `;
                // Wait until the iframe is loaded
                iframe.addEventListener('load', () => {
                    iframe.contentDocument.head.appendChild(style);
                    // Ad a div to the iframe to hold the glyphs
                    const glyphs = document.createElement('div');
                    glyphs.classList.add('glyphs');
                    iframe.contentDocument.body.appendChild(glyphs);
                    // Loop through the glyphs in the parsed font
                    for (const glyph of Object.values(this.state.parsedFont.glyphs).reverse()) {
                        if (!glyph.unicode) {
                            continue;
                        }
                        const unicode = glyph.unicode;
                        // Skip control characters
                        if (unicode <= 32) {
                            continue;
                        }
                        // Create a span for each glyph
                        const span = document.createElement('span');
                        span.classList.add('glyph');
                        // Convert the unicode to a string
                        const char = String.fromCodePoint(unicode);   
                        let trimmed = char + ' ';
                        trimmed.trim();                     
                        if (trimmed === '') {
                            continue;
                        }
                        // Skip combining marks (unicde 768 - 879)
                        if (unicode >= 768 && unicode <= 879) {
                            continue;
                        }
                        // Add an empty space (&nbsp) before and after the char
                        span.innerHTML = char;
                        // Add a click event listener to the span
                        span.addEventListener('click', () => {
                            navigator.clipboard.writeText( char );
                            Message.showMessage({
                                duration: 2000,
                                messageText: 'Copied',
                                preventClickout: false,
                            });
                        });
                        glyphs.appendChild(span);
                    }
                });
                // Add the iframe to the glyphsRef
                this.glyphsRef.current.appendChild(iframe);
            }
        }
    }


    getFeatureLabelFromTag(tag) {
        const lookup = {
            'aalt': 'Access All Alternates',
            'calt': 'Contextual Alternates',
            'case': 'Case-Sensitive Forms',
            'dnom': 'Denominators',
            'numr': 'Numerators',
            'clig': 'Contextual Ligatures',
            'dlig': 'Discretionary Ligatures',
            'frac': 'Fractions',
            'rlig': 'Required Ligatures',
            'hist': 'Historical Forms',
            'fwid': 'Full Width',
            'hwid': 'Half Width',
            'liga': 'Standard Ligatures',
            'lnum': 'Lining Figures',
            'locl': 'Localized Forms',
            'onum': 'Oldstyle Figures',
            'ordn': 'Ordinals',
            'pnum': 'Proportional Figures',
            'salt': 'Stylistic Alternates',
            'sinf': 'Scientific Inferiors',
            'subs': 'Subscript',
            'sups': 'Superscript',
            'swsh': 'Swashes',
            'titl': 'Titling',
            'tnum': 'Tabular Figures',
            'zero': 'Slashed Zero',
            'ccmp': 'Glyph Composition/Decomposition',
            'fina': 'Terminal Forms',
            'init': 'Initial Forms',
            'medi': 'Medial Forms',
            'rvrn': 'Required Variation Alternates',
            'smcp': 'Small Capitals',
            'unic': 'Unicase',
            'c2sc': 'Small Capitals From Capitals',
            'cpsp': 'Capital Spacing',
            'ornm': 'Ornaments',
        }

        if (tag.startsWith('cv')) {
            const number = parseInt(tag.slice(2));
            return `Character Variant ${number}`;
        }

        if (tag.startsWith('ss')) {
            const number = parseInt(tag.slice(2));
            return `Stylistic Set ${number}`;
        }

        return lookup[tag] || tag;
    }

    handleMount = async (force = false) => {
        let src;

        try {
            src = await getFontSrc({
                fontFamily: this.props.propWatcherValues[this.props.selector]?.['font-family'],
                fontWeight: this.props.propWatcherValues[this.props.selector]?.['font-weight'],
                fontStyle: this.props.propWatcherValues[this.props.selector]?.['font-style'],
            });
        } catch (e) {
            console.error('Error retrieving font src', e);
        }

        let parsed = {
            tables: {
                gsub: {
                    features: [],
                }
            },
            glyphs: {},
        }
        
        if (src && src.url !== this.state.src?.url) {
            try {
                parsed = await parseFont(src);
            } catch (e) {
                console.error('Error parsing font', e);
            }
        }

        if (src && src.url === this.state.src?.url) {
            parsed = this.state.parsedFont;
        }

        const features = {};

        if (parsed?.tables?.gsub?.features) {
            for (const feature of parsed.tables.gsub.features) {
                if (features[feature.tag]) {
                    continue;
                }
                features[feature.tag] = {
                    label: this.getFeatureLabelFromTag(feature.tag),
                    alternates: getAlternatesFromFeature(feature, parsed),
                    alternatesStr: getAlternatesStrFromFeature(feature, parsed, src),
                }
            }
        }

        // Handle special cases for Gaisyr fonts
        if (src?.url.startsWith('https://type.cargo.site/files/CargoGaisyr')) {
            features['dlig'] = {
                label: 'Discretionary Ligatures (italic)',
                alternates: ['dlig'],
                alternatesStr: 'Discretionary Ligatures',
            }
            features['swsh'] = {
                label: 'Swashes (italic)',
                alternates: ['swsh'],
                alternatesStr: 'Swashes',
            }
        }

        this.setState({
            parsedFont: parsed,
            src,
            features,
        });
    }

    onChange = (changes) => {
        let existingFontFeatureSettingsArr = this.props.propWatcherValues[this.props.selector]?.['font-feature-settings']?.split(',') || [];
        // Remove any whitespace from the existing font-feature-settings arr values
        existingFontFeatureSettingsArr = existingFontFeatureSettingsArr.map(item => item.trim());
        let newFontFeatureSettingsArr = [...existingFontFeatureSettingsArr];

        for (const tag in changes) {
            if (changes[tag] === true) {
                // If the tag is not already in the array, add it
                if (!newFontFeatureSettingsArr.includes(`"${tag}"`)) {
                    newFontFeatureSettingsArr.push(`"${tag}"`);
                }
            } else {
                // If the tag is in the array, remove it
                if (newFontFeatureSettingsArr.includes(`"${tag}"`)) {
                    newFontFeatureSettingsArr = newFontFeatureSettingsArr.filter(item => item !== `"${tag}"`);
                }
            }
        }

        let newFontFeatureSettings = newFontFeatureSettingsArr.join(', ');
        newFontFeatureSettings.trim();

        if (newFontFeatureSettings === '') {
            newFontFeatureSettings = null;
        }

        this.props.queuePropwatcherChanges({
            [this.props.selector]: {
                'font-feature-settings': newFontFeatureSettings,
            }
        });



    }

}



function mapReduxStateToProps(state, ownProps) {
  let fontFiles = state.media.data.filter(item => {
    return !item.is_image && !item.loading && item.crdt_state !== CRDTState.Deleted && (item.file_type.toLowerCase() === 'woff' || item.file_type.toLowerCase() === 'woff2')
  }) 

  fontFiles.forEach(fontModel=>{
   fontModel.fullURL = `https://freight.cargo.site/m/${fontModel.hash}/${fontModel.name}`
  });

	return {
        fontFiles,
        fontCollection: state.fontCollection,
        fontPickerOpen: state.uiWindows?.focusOrder?.indexOf('font-picker') !== -1,
	};
}

// create a memoized propwatcher config. This ensures we only reload 
// propWatchers if anything in the config has actually changed
const memoizedWatchedProperties = createSelector(
	(ownProps) => ownProps.selector,
	(
		selector,
	) => {
		return {
			[selector]: [
				'font-feature-settings',
                'font-family',
                'font-weight',
                'font-style',
                'font-variation-settings'
			],
		}
	}
);

function mapDispatchToProps(dispatch) {
	
	return bindActionCreators({

    fetchFontCollection:actions.fetchFontCollection,
    fetchCustomFontCollection: actions.fetchCustomFontCollection,
		updatePage: actions.updatePage,
		addUIWindow: actions.addUIWindow,
		removeUIWindow: actions.removeUIWindow,
		updateUIWindow: actions.updateUIWindow
	}, dispatch);

}

export default connect(
	mapReduxStateToProps,
	mapDispatchToProps
)(withPropWatchers(TypeSettingsGlyphs,  {
    parser: ownProps => ownProps.type === 'local' ? 'local' : 'global',
    watchedProperties: memoizedWatchedProperties,
}));

export { getFontSrc, parseFont, getAlternatesStrFromFeature, StylisticSetButton }