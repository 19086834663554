import { sharedReducerMap } from "../multi-user/redux";
import { Y } from "../multi-user";

let observer = null;

export const setupCSSEditorListener = (binding, opts) => {
  if (!binding.type.doc) {
    // If the yText hasn't been synced, observe the CRDT and wait for an update
    const sharedReducer = opts.pageId
      ? sharedReducerMap.get("pages.byId")?.type
      : sharedReducerMap.get("css")?.type;
    observer = CRDTChangeListener(opts.pageId, sharedReducer, binding);
    sharedReducer.observeDeep(observer);
  }
};

const CRDTChangeListener =
  (pageId = null, sharedReducer, binding) =>
  (events, transaction) => {
      events.forEach((event) => {
        if (
          ((pageId && event.path[0] == pageId) || !pageId) &&
          event.target instanceof Y.Text
        ) {
          const incomingCSS = event.target;

          if (incomingCSS instanceof Y.Text) {
            // Replace the binding's type with the incoming yText;
            binding.transplantType(incomingCSS)
          }

          sharedReducer.unobserveDeep(observer);
        }
      });
  };
