import React from 'react';
import { connect } from 'formik';
import omitBy from 'lodash/omitBy';
import _ from 'lodash';
import deepDiff from "../../lib/deepDiff"

function flattenObject(field, pathToField = [], result = new Map()) {

	if (!field || typeof field !== 'object') {
	
		// the field is not another object, so we hit the end. Set it's
		// final path and value in the resulting map
		result.set(pathToField, field);
	
	} else {

		// the field is an object, recurse down it's keys
		Object.keys(field)
			.flatMap((key) =>
				flattenObject(
					field[key],
					[...pathToField, key],
					result
				)
			)
	}

	return result
}

class ChangeListener extends React.Component {

	render() {
		return null;
	}

	componentDidUpdate(old) {

		// we can set a field to 'touched' to force it to rerender
		// eg. typing '4' in an un-synced padding scrubber that's already '4'
		const formWasTouched = Object.keys(this.props.formik.touched).length > 0;

		if(this.props.formik.dirty === false && !formWasTouched) {
			return;
		}

		// when state updates are backed up (heavy layout update, etc) a form can be reset AND update at the same time
		// make sure that these changes go through
		let changes = deepDiff(old.formik.values, this.props.formik.values, true);

		if( formWasTouched ){

			if ( !changes ){
				changes = {}
			}

			// get a list of paths to the touched keys, then set create a change object
			// out of only those changes
			flattenObject(this.props.formik.touched).forEach((value, path) => {
				_.set(changes, path, _.get(this.props.formik.values, path))
			})

			this.props.formik.setTouched({}, false);

		}


		if (!changes && !_.isEqual(old.formik.initialValues, this.props.formik.initialValues) ) {
			/* Component is updating because Formik's defaults have changed (this happens when
			 * enableReinitialize is set to true and two forms share the same data set).
			 * 
			 * We can ignore this change becuase the other form will already have reported the appropriate
			 * change.
			 */
		
			return;

		}

		if(changes) {
			this.props.onChange(changes, this.props.formik);
		}

	}

};


export default connect(ChangeListener);