import _ from 'lodash';
import { FRONTEND_DATA } from "../globals";

export default function deepDiff (obj1, obj2, keepNewKeys = false) {
  if (typeof obj1 !== 'object') {
    throw new TypeError('First parameter must be an object')
  }

  if (typeof obj2 !== 'object') {
    throw new TypeError('Second parameter must be an object')
  }

  const diff = {}

  Object.keys(obj2).forEach((key) => {
    if (!obj1.hasOwnProperty(key)) {
      if (keepNewKeys) {
        diff[key] = obj2[key]
      }
      return
    }

    if (obj1[key] === null || obj2[key] === null) {
      if (obj1[key] !== obj2[key]) {
        diff[key] = obj2[key]
      }
      return
    }

    if (typeof obj2[key] === 'object') {
      if (
        obj2[key] instanceof Array
        || obj2[key] instanceof FRONTEND_DATA.contentWindow.Array
      ) {
        if (!_.isEqual(obj2[key], obj1[key])) {
          diff[key] = obj2[key]
        }

        return
      }

      if (
        !(obj1[key] instanceof Object)
        && !(obj1[key] instanceof FRONTEND_DATA.contentWindow.Object)
      ) {
        diff[key] = obj2[key]

        return
      }

      diff[key] = deepDiff(obj1[key], obj2[key], keepNewKeys)

      //  Don't keep trace of a null key if it was generated by a recursive use of deepDiff.
      if (diff[key] === null) {
        delete diff[key]
      }

      return
    }

    if (
      obj1[key] !== obj2[key]
      && !(_.isNaN(obj1[key]) && _.isNaN(obj2[key]))
    ) {
      diff[key] = obj2[key]
    }
  })

  // If there's no difference between the two objects, return null
  if (Object.keys(diff).length === 0) {
    return null
  }

  return diff
}